
.containerPage{
    display: flex;
    .Titulo{
        position: absolute;
        min-height: 15vh;
        min-width: 30vw;
        margin-left: 70vw;
        margin-top: 85vh;
        color: rgb(255, 255, 255);
        z-index: 5;
        text-align: right;
        font-size: 2rem;
        font-weight: 800;
        font-family: Helvetica;
        text-transform: uppercase;
        letter-spacing: 0.9rem;
    }
    .filtro{
        position: absolute;
        z-index: 2;
        background-color : rgba(0,0,0,0.5);
        min-width: 100vw;
        min-height: 100vh;
    }

    .letras{
        z-index: 5;
        position: absolute;
        color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        min-height: 60vh;
        margin-left: 2vw;
        margin-top: 2vw;
        justify-content: space-around;
        align-items: flex-start;
        font-weight: 800;
        font-family: Helvetica;
        text-transform: uppercase;
        letter-spacing: 0.9rem;
    }
}


.containerPageMobil{
    max-width: 100vw;
     #containerVideo{
        max-width: 100vw;
     }
}

.containerPageMobil #containerVideo {    
    max-height: 70vh;
}

.containerVideos{
    min-height: 100vh;
    background-color: #000;
}














